import { useRouter } from 'next/router';
import Script from 'next/script';
import { useSelector } from 'react-redux';

const OneTapGoogle = () => {
  const router = useRouter();
  const isUserLoggedIn = useSelector((state) => state.auth.isUserLoggedIn);
  const isAuthLoading = useSelector((state) => state.auth.isAuthLoading);
  const cookiePermissions = useSelector(
    (state) => state.persistor.cookiePermissions
  );
  // If the user is not logged in and the authentication is not loading, render the Google One Tap card and script
  if (
    !isUserLoggedIn &&
    !isAuthLoading &&
    router.asPath === '/' &&
    cookiePermissions.googleSSO
  ) {
    return (
      <>
        <div
          id='g_id_onload'
          data-client_id={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}
          data-context='signin'
          data-login_uri={process.env.NEXT_PUBLIC_GOOGLE_ONE_TAP_LOGIN_URL}
          data-nonce=''
          data-itp_support='true'
          data-cancel_on_tap_outside='false'
        />
        <Script
          id='g_id_init'
          src='https://accounts.google.com/gsi/client'
          strategy='lazyOnload'
        />
      </>
    );
  }

  // If the user is logged in or the authentication is loading, return null
  return null;
};

export default OneTapGoogle;
